<template>
    <Toast/>
	<div class="grid crud-demo">
		<div class="flex col-12">
            <div class="surface-card p-4 shadow-2 border-round">
                <p>Contact us for feature requests or bug reports, or other general requests/comments.</p>
                <Textarea v-model="contact" :autoResize="true" rows="5" placeholder="Write here." />
                    <br>
                    <br>
                <Button
                  label="Submit"
                  icon="pi pi-check"
                  class="w-1/4"
                  v-on:click="submit"
                ></Button>
                <p></p>
                <p>Or, directly contact us via E-Mail: info@ludi.coach</p>
            </div>
		</div>
	</div>
</template>

<script>
import UserService from '../service/UserService';

export default {
    data() {
        return {
            contact: "",
        };
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {
        submit() {
            this.userService.createContact({
                contact: this.contact,
            }).then(data => {
                if ("error" in data) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: 'Could not send contact request: ' + data["error"], life: 8000});
                } else {
                    this.$toast.add({severity: 'info', summary: 'Success', detail: 'Successfully submitted your message.', life: 7000});
                    this.contact = "";
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
textarea {
    width: 100%;
}
</style>
